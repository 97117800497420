import { useLocation, useNavigate } from "react-router-dom";
import { useRef } from "react";
import { useEscapeKey, useClickOutside } from "../util.js";
import { useProfiles } from "../lib/profiles.js";

function toggleHidden(el) {
  el?.classList?.toggle("hidden")
}

function hide(el) {
  el?.classList?.add("hidden");
}

function hideDropDown() {
  const dd = document.getElementById("twinmgr-dd");
  // FIXME(sfertman): refactor this / use refs instead
  if (!dd) return;
  dd.classList.add("hidden")
}

export function ProfileTile({p}) {
  // FIXME(sfertman): refactor this to use refs and data instead of parsing profile from element id
  const location = useLocation();
  const navigate = useNavigate();
  const {getProfile, listProfiles, activeProfile,  setActiveProfile, updateProfile, removeProfile} = useProfiles();

  function isActive(profile) {
    return profile == activeProfile()
  }

  function isLoggedIn(profile) {
    return !!getProfile(profile)?.client?.apiKey;
  }

  function switchToOnClick(e) {
    let profile = e.target.id.split("switch-to-")[1];
    if (isActive(profile)) {
      return hideDropDown();
    }

    if (isLoggedIn(profile)) {
      setActiveProfile(profile);
      if (!location.pathname.startsWith("/login")) {
        navigate(location.pathname);
      } else {
        navigate("/home");
      }
    } else {
      navigate(`/login?twin-url=${encodeURIComponent(profile)}`);
    }
    hideDropDown();
  }


  function logoutOnClick(e) {
    let profile = e.target.id.split("logout-")[1];
    updateProfile(profile, cfg => {
      let url = cfg.client?.url;
      return { ...cfg, client: { url }};
    });
    hideDropDown();

    let nextLoggedIn = listProfiles().find(p =>  p != profile && isLoggedIn(p));
    if (nextLoggedIn) {
      console.log(nextLoggedIn)
      setActiveProfile(nextLoggedIn);
      navigate("/home");
    } else {
      setActiveProfile(null);
      navigate("/login");
    }
  }

  function removeOnClick(e) {
    let profile = e.target.id.split("remove-")[1];
    removeProfile(profile);
    hideDropDown();
  }

  function displayTwinUrl(p) {
    if (!p) return "";
    return p.replace(/https?:\/\//, "").split(/[0-9a-f]\./i);
  }

  return <div className="px-1 hover:bg-zinc-700 max-w-fit text-nowrap">
    <div className="flex flex-row py-1">
      <button id={`switch-to-${p}`}
              data-active={isActive(p)}
              title={`Switch to: ${p}`}
              className="grow text-left px-2 data-[active=true]:text-blue-400"
              onClick={switchToOnClick}>
        {displayTwinUrl(p)[0]}<br/>
        <span className="italic text-xs brightness-75" >@{displayTwinUrl(p)[1]}</span>

      </button>
      {isLoggedIn(p) &&
        <button id={`logout-${p}`}
                title={`Logout: ${p}`}
                onClick={logoutOnClick}
                className="p-4 rounded self-center size-6 bg-[url(/public/img/logout.svg)] hover:bg-zinc-800 bg-no-repeat"></button>}
      {!isLoggedIn(p) &&
      <button id={`remove-${p}`}
              title={`Remove: ${p}`}
              onClick={removeOnClick}
              className="size-8 rounded hover:bg-red-500 hover:text-toda-gray px-2 text-center items-center self-center align-middle text-xl">{"\u00D7"}</button>}
    </div>
  </div>
}

export function ProfileManager() {
  const navigate = useNavigate()
  let dropDownRef = useRef(null);
  const { active, listProfiles, getProfile } = useProfiles();

  function sortProfiles() {
    function isLoggedIn(profile) { // oof; refactor this mess
      return !!getProfile(profile)?.client?.apiKey;
    }
    let profiles = listProfiles().filter(p => p != active);//.sort();
    let loggedIn = profiles.filter(p => isLoggedIn(p)).sort();
    let loggedOut = profiles.filter(p => !isLoggedIn(p)).sort();
    return (active ? [active] : []).concat(loggedIn).concat(loggedOut);
  }

  useEscapeKey(() => hide(dropDownRef?.current));
  useClickOutside(() => hide(dropDownRef?.current), dropDownRef);
  return <div className="ml-2 font-mono flex flex-col rounded select-none items-end min-w-fit">
    <div className="flex grow">
      <button
        className="h-8 w-8 border-2 border-transparent hover:bg-zinc-700 rounded-lg bg-[url(/public/img/user.svg)] bg-no-repeat relative right-0"
        onClick={() => toggleHidden(dropDownRef.current)}
        ></button>
    </div>

    <div id="twinmgr-dd"
         ref={dropDownRef}
         className="hidden z-50 border rounded min-w-36 border-zinc-300  bg-zinc-800 p-3 absolute top-12 text-sm ">
      {sortProfiles().length > 0 &&
        <div>
          {sortProfiles().map(p => <ProfileTile key={p} p={p}/>)}
          <hr className="my-1"></hr>
        </div>}
      <button onClick={(_) => { hide(dropDownRef.current); navigate("/login")}}
        className=" flex flex-row flex-nowrap ~rounded px-3 hover:bg-zinc-700 text-nowrap text-left h-8 w-full">
        <div className="align-baseline h-full w-7 bg-no-repeat bg-center bg-[url(/public/img/add-twin.svg)] "></div>
        <p className="pl-3 py-1 align-middle text-base font-sans">Add another twin</p>
      </button>
    </div>
  </div>
}
