import { useEffect, useMemo, useRef, useState } from "react";
import { AssetComponent } from "./Asset";
import { Header } from "../Components";
import { fromBytes as ssrFromBytes } from "../lib/ssr";

export function ValidateAsset() {
  let [ file, setFile ] = useState(null);
  let fileInput = useRef(null);

  let [ clicked, setClicked ] = useState(false);
  useEffect(() => {
    // NOTE(sfertman): I'm out of ideas on how to convince react
    // to not useEffect twice; this is my hack and I'm sticking
    // with it.
    if(!clicked) {
      if (fileInput?.current) {
        fileInput.current.click();
        setClicked(true)
      }
    }
  }, [clicked, fileInput]);


  async function fileSelect(e) {
    e.preventDefault();
    let fileInput = e.target.files?.[0];
    if (fileInput) {
      setFile({
        name: fileInput.name,
        bytes: await fileInput.arrayBuffer()
      });
    }
  }

  let ssr = useMemo(() => {
    // NOTE(sfertman): this is the third time I'm using this same code; should really factor it out.
    let fileBytes = file?.bytes;
    if (!fileBytes) return null;
    if (fileBytes.data) return null;
    try {
      // let bytes = hexToBytes(fileBytes);
      let ssr = ssrFromBytes(fileBytes);
      if (!ssr) return null;
      try {
        ssr.content = JSON.parse(ssr?.content); // try parsing content as json
      } catch (_) { }
      return ssr
    } catch (e) {
      console.warn(e);
    }
  }, [file]);
  return <>
    <Header />
    {!ssr
      ?  <input ref={fileInput}
                type="file"
                className="file:border-0 file:text-white file:bg-gray-500 file:rounded-md file:font-medium file:text-sm file:px-5 file:py-2 focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:cursor-not-allowed disabled:opacity-50"
                onChange={fileSelect}
                />
      : <AssetComponent  ssr={ssr}/>}
  </>
}
