import { useMemo } from "react";
import axios from "axios";
import { TwinClient as TwinClient0 } from "twin-client/src/client.js";
import { useProfiles } from "../profiles";

/**
 * Copied as is from todajs because it's not exported and I need it here.
 * @param {int} offset starting byte
 * @param {int} length total length of bytes
 * @returns {string}
 **/
export function bytesToHex(bytes, offset=0, length=0) {
  let hex = '';
  const hh = Array.from(Array(256)).map((_,i)=>i.toString(16).padStart(2, '0'));
  const o = offset || 0;
  const l = o + (length || bytes.byteLength);
  for (let i = o; i < l; i++) {
      hex += hh[bytes[i]];
  }
  return hex;
}

export function hexToBytes(str) {
  let result = [];
  for (let i = 0; i < str.length; i += 2) {
      result.push(parseInt(str.substring(i, i + 2), 16));
  }
  return new Uint8Array(result);
}

export class TwinClient extends TwinClient0 {

  static getLatestTether(current) {
    if (!current) {
      current = "4172f9e82c0af9eff943d41ff7b7d8c40741aaba8a10e6f09ebb1c011650f11945"

      // ^^ we may want to update this every now and again
    }
    // NOTE: not using this.request because we're getting from relay.todaq.net
    // Ideally this should be RelayClient class
    return axios.get(`https://relay.todaq.net/api/ticket/${current}`)
      .then(res => res.data.itinerary[0].twist)

  }

  static async test(url) {
    return (new this({ url })).info();
  }

  async address() {
    if (!this.twinAddress) {
      this.twinAddress = (await this.info()).address;
    }
    return this.twinAddress;
  }

  async binder() {
    return this.request({
      method: "GET",
      url: "/binder"
    });
  }

  async balance(typeHash) {
    if (typeHash) {
      return super.balance(typeHash);
    }
    return this.request({
      method: "GET",
      url: "/dq"
    });
  }

  /**
   *
   * @param {Uint8Array} bytes
   * @param {Object} opts
   * @param {string} opts.fileName
   * @param {string} opts.timeCreated
   * @param {string} opts.timeModified
   * @param {string} opts.description
   * @returns
   */
  async createFile(bytes, opts) {
    return await this.request({
      method: "POST",
      url: "/files/create",
      params: opts,
      headers: {
        "content-type": "application/octet-stream"
      },
      data: bytes,
      responseType: "arraybuffer"
    });
  }


  async createSSR(content) {
    return await this.request({
      method: "POST",
      url: "/ssr",
      data: content
    });
  }

  async getSSR(hash) {
    return await this.request({
      method: "GET",
      url: `/ssr/${hash}`,
    });
  }

  async listFiles() {
    let buff = await this.request({
      method: "GET",
      url: "/toda",
      responseType: "arraybuffer"
    });
    let arr = new Uint8Array(buff);
    let list = [];
    for (let i = 0; i < arr.length; i += 33) {
      let arrSlice = arr.slice(i, i + 33)
      let b2h = bytesToHex(arrSlice);
      list.push(b2h);
    }
    return list;
  }

  async transferAsset({ hash, destination }) {
    return this.request({
      method: "POST",
      url: `/files/${hash}/transfer`,
      data: { destination }
    });
  }

  async listInventory() {

  }

  verifyLink(h) {
    return `https://verify.todaq.net/#${encodeURIComponent(`${this.url}/toda/${h}?apiKey=${this.apiKey}`)}`;
  }

  analyzeLink(h) {
    return `https://trie.fun/analyzer/#${this.url}/toda/${h}?apiKey=${this.apiKey}`;
  }

  downloadLink(h) {
    return `${this.url}/toda/${h}?apiKey=${this.apiKey}`;
  }
}

export class AdminTwinClient extends TwinClient0 { // no need for this unfortunately

  async createTwin(config) {
    return this.request({
      method: "POST",
      url: "/admin/twins/create",
      data: {
        clientVersion: "V2",
        relayUrl: "https://relay.todaq.net/api/hoist",
        remoteFileServerUrl: "https://cdn.relay.todaq.net/next",
        ...config
      }
    });
  }
}

export function getClient({client} = {}) {
  if (client) {
    return new TwinClient(client);
  }
}

export function getActiveClient() {
  const { getActiveProfile } = useProfiles();
  return getClient(getActiveProfile())
}

/**
 *
 * @returns {TwinClient}
 */
export function useActiveClient() {
  let { active, getActiveProfile } = useProfiles();
  let client = useMemo(() => getClient(getActiveProfile()), [active]);
  return client;
}
