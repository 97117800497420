import { createContext, useContext, useEffect, useState } from "react";
import { ReactComponent as AliasSVG } from "../svg/user.svg";
import { HashDisplay } from "./index";

export function AddressCard({ h, state }) {
  if (!state) {
    state = useState(false);
  }
  let [isEditing, setIsEditing] = state;
  const { getAlias, setAlias } = useAddressAlias();

  function AliasDisplay({alias, h, onClick, ...props}) {
    return <div className="flex flex-row gap-1" {...props}>
      <div className="size-6 bg-left bg-no-repeat bg-[url(/public/img/user.svg)] cursor-pointer" onClick={onClick}></div>
      <div className="flex flex-col ~ml-2">
        <div className="h-full text-left text-base " >
          {alias}
        </div>
        {/* <HashDisplay h={h} fontSize={"12px"}/> */}
      </div>
    </div>
  }

  function AddressDisplay({ h }) {
    const alias = getAlias(h);
    return <div className="rounded flex justify-end ~max-w-fit">
      { alias ?
        <div className="flex flex-col group/hash">
          <div title="Edit Alias"
                className="~h-3/4 ~cursor-pointer">
            <AliasDisplay alias={alias} h={h} onClick={() => setIsEditing(true)}/>
          </div>
        </div>

        :

        <div className="flex flex-row group/address" >
          <div className="flex-1 w-[85%] h-full">
           <HashDisplay h={h} fontSize={"14px"} />
          </div>
          <div title="Edit alias"
               className="hidden group-hover/address:block h-full ~w-7 cursor-pointer ~bg-[url(/public/img/user.svg)] ~bg-no-repeat"
               onClick={() => setIsEditing(true)}>
            <AliasSVG className="h-full rounded-sm active:backdrop-brightness-90" style={{width: "16px"}} />
          </div>
        </div>
      }
    </div>
  }

  function AliasInput({ h }) {

    function handleKeyDown(e) {
      if (e.key == "Escape") {
        try {
          e.target.form.reset(); // this is new here
          e.target.blur();
          setIsEditing(false);
        } catch (e) {
          console.warn("Error in Esc key handler", e);
        }
      }

      if (e.key == "Enter") {
        try {
          // let event = new Event("submit", {cancelable:true})
          e.target.form.requestSubmit()
          e.preventDefault()
        } catch (e) {
          console.warn("Error in Enter key handler", e);
        }
      }
      return; // must return otherwise the key is not propagated
    }

    function submitAlias(e) {
      e.preventDefault(); // figure out a way to re-render component without refreshing the page
      let formData = new FormData(e.target);
      let newAlias = formData.get("alias")
      setAlias(h, newAlias);
      e.target.elements.alias.value = newAlias
      e.target.elements.alias.blur()
      setIsEditing(false)
      return
    }

    function resetAlias(e) {
      setAlias(h, null);
      e.target.form.elements.alias.value = null
      setIsEditing(false);
    }

    return (
      <div className="max-w-fit text-black">
        <form onSubmit={submitAlias}
          className="flex flex-col p-2 rounded bg-zinc-500" >
          <div className="flex flex-row min-w-fit">
            <div className="pr-2">
              <AliasSVG className="h-7 w-auto" />
            </div>
            <div className="border border-black flex flex-row rounded min-w-fit bg-white focus-within:outline outline-2 outline-blue-600 "

            >
              <input
                autoFocus
                name="alias"
                placeholder="Input Hash Alias"
                defaultValue={getAlias(h)}

                onKeyDown={handleKeyDown}
                onBlur={() => setTimeout(() => setIsEditing(false), 250)}
                // NOTE(sfertman): to give resetAlias a chance to fire
                className="px-2 max-h-min text-wrap focus:outline-none bg-transparent"
              />
              <button
                type="button"
                title="Reset alias"
                onClick={resetAlias}
                className="px-[6px] rounded-r h-full align-middle text-start text-red-600 hover:text-white hover:bg-red-500">
                {"\u00D7"}
              </button>
            </div>
          </div>
          <div className="pt-1 max-w-fit">
            <HashDisplay h={h} fontSize={"12px"} />
          </div>
        </form>
      </div>
    )
  }
  return (<div className="">

    {isEditing ? <AliasInput h={h} /> : <AddressDisplay h={h} />}
  </div>);
}

export const AddressAliasContext = createContext();
export function AddressAliasContextProvider(props) {

  const [aliases, setAliases] = useState(() => {
    const stored = localStorage.getItem("aliases");
    return stored ? JSON.parse(stored) : {};
  });

  useEffect(() => {
    localStorage.setItem("aliases", JSON.stringify(aliases));
  });

  function getAlias(address) {
    return aliases[address];
  }

  function setAlias(address, alias) {
    return setAliases(oldval => ({...oldval, [address]: alias}));
  }

  return <AddressAliasContext.Provider value={{ getAlias, setAlias }}>
    {props.children}
  </AddressAliasContext.Provider>
}

export function useAddressAlias() {
  return useContext(AddressAliasContext);
}
