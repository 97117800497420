import "./index.css"
import React from "react"
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { QueryClient } from "@tanstack/react-query";
import { PersistQueryClientProvider } from '@tanstack/react-query-persist-client'
import { createAsyncStoragePersister } from '@tanstack/query-async-storage-persister'
import App from "./App";
import { ProfilesContextProvider } from "./lib/profiles";
import { AddressAliasContextProvider } from "./Components/Address";

// handle dark mode
if (localStorage.theme === 'dark' || (!('theme' in localStorage) && window.matchMedia('(prefers-color-scheme: dark)').matches)) {
  document.documentElement.classList.add('dark')
} else {
  document.documentElement.classList.remove('dark')
}

const asyncStoragePersister = createAsyncStoragePersister({
  storage: localStorage,
  key: "reactQueryPersistentCache"
});

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false
    }
  }
});

const root = createRoot(document.getElementById("root"));
root.render(<React.StrictMode>
  <div className="flex justify-center">
    <BrowserRouter>
      <PersistQueryClientProvider client={queryClient} persistOptions={{ persister: asyncStoragePersister }}>
        <AddressAliasContextProvider>
          <ProfilesContextProvider>
            <App />
          </ProfilesContextProvider>
        </AddressAliasContextProvider>
      </PersistQueryClientProvider>
    </BrowserRouter>
  </div>
</React.StrictMode>);
