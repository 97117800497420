import { useCallback, useEffect, useRef } from "react";

export function delay(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

export function selectHashes(h) {
  return document.querySelectorAll(`[title="${h}"]`)
}

export function useClickOutside(callback, ref) {

  // Based on:
  // - https://stackoverflow.com/a/54292872
  // - https://www.youtube.com/watch?v=J-g9ZJha8FE&feature=youtu.be&t=481

  let handleClickOutside = useCallback((e) => {
    if (!ref?.current?.contains(e.target) && callback) {
      callback(e);
    }
  }, [ref]);

  useEffect(() => {
    if (ref?.current) {
      document.addEventListener("click", handleClickOutside, true);
      return () => {
        document.removeEventListener("click", handleClickOutside, true);
      }
    }
  }, [ref.current, handleClickOutside]);

  return ref;
}

export function useEscapeKey(callback) {
  let handleEscKey = useCallback((event) => {
    if (event.key == "Escape") {
      callback(event);
    }
  }, [callback]);

  useEffect(() => {
    document.addEventListener("keyup", handleEscKey, false);
    return () => {
      document.removeEventListener("keyup", handleEscKey, false);
    };
  }, [handleEscKey]);
}
