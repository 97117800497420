import { Twist } from "./todaadot/src/core/twist.js"
import { Abject } from "./todaadot/src/abject/abject.js";
import { AssetClassField, DI, DIAssetClassClass } from "./todaadot/src/abject/di.js";
import { P1Date, P1String } from "./todaadot/src/abject/primitive.js";
import { Actionable, SimpleRigged } from "./todaadot/src/abject/actionable.js";
// SimpleRigged must be imported for SSR to work


// import { Abject, TodaClient, SimpleRigged, P1String, P1Date, DI, DIAssetClassClass, AssetClassField, Twist } from "todajs";

// ---- SELECTIVELY COPY/PASTED FROM todatwin/server/src/ssr.js ----

const SYM_TS = Actionable.gensym("field/relay/ts");

export const SSR = new DIAssetClassClass();
SSR.fieldSyms = {
    fLogTimestamp: Abject.gensym("ssr/timestamp"),
    fLogContent: Abject.gensym("ssr/content"),
};

let fLogTimestamp = new AssetClassField();
fLogTimestamp.type = P1Date.interpreter;
fLogTimestamp.required = true;

let fLogContent = new AssetClassField();
fLogContent.type = P1String.interpreter;
fLogContent.consolidation = DI.consolidations.append;
fLogContent.required = true;

SSR.addACField(SSR.fieldSyms.fLogTimestamp, fLogTimestamp);
SSR.addACField(SSR.fieldSyms.fLogContent, fLogContent);

// ---- SELECTIVELY COPY/PASTED FROM todatwin/server/src/ssr.js ----

/**
 *
 * @param {*} bytes byte array
 * @returns {object} Returns parsed SSR object. Returns null if cannot be parsed or does not contain SSR specific fields: `timestamp` and `content`
 */
export function fromBytes(bytes) {
  let current = Twist.fromBytes(bytes);
  let first = current.first();
  // NOTE(sfertman): ^ extracting content from the first twist only à la todaized
  if (!first) return null;
  let abject = Abject.fromTwist(first);
  if (!abject) return null;
  let di = abject.getContext();
  let timestamp = di.getFieldAbject(SSR.fieldSyms.fLogTimestamp);
  let content = di.getFieldAbject(SSR.fieldSyms.fLogContent);

  if (!timestamp || !content) return null;

  let atoms = current.getAtoms();
  let history = current.knownHistory().map(h => new Twist(atoms, h));

  return { current, first, history, abject, timestamp, content };
}
