import { useNavigate } from "react-router-dom";
import { Header, PageTitle } from "../Components";
import { ButtonCancel, ButtonOk } from "../Components/Buttons";
import { useMemo, useRef, useState } from "react";
import { useActiveClient } from "../lib/client";
import { useMutation } from "@tanstack/react-query";
import { Spinner } from "../Components/Spinner";

//VERIFICATION REPORT DRUG BILL OF LADING
// ^^ is title of file / asset

export default function CreateAsset() {
  let navigate = useNavigate();
  let twinClient = useActiveClient();
  let { mutate: createSSR } = useMutation({
    mutationFn: function createSSR(content) {
      return withSpinner(_=> twinClient.createSSR(content))
    },
    onSuccess: function createSSROnSuccess(data) {
      console.log(data)
      console.log(data.twistId)
      navigate(`/asset/${data.twistId}`)
      // setNewFileHash(bytesToHex(new Uint8Array(data)))
      // needs to navigate somewhere; maybe asset/:hash
    }
  });

  let [file, setFile] = useState(null)

  let fileBase64 = useMemo(() => {
    // This is SOOOOO stupid; there must be a better way in 2024...
    if (!file) return "";
    let bytes = new Uint8Array(file.bytes);
    let str = "";
    for (let i=0; i < bytes.length; i++) {
      str += String.fromCharCode(bytes[i]);
    }
    return window.btoa(str)
  }, [file])

  async function fileSelect(e) {
    e.preventDefault();
    let fileInput = e.target.files?.[0];
    if (fileInput) {
      setFile({
          name: fileInput.name,
          bytes: await fileInput.arrayBuffer(),
          fileName: fileInput.name,
          fileMime: fileInput.type
      });
    }
  }

  function onSubmitForm(e) {
    e.preventDefault();
    let formData = new FormData(e.target);
    createSSR({
      name: formData.get("name"),
      description: formData.get("description"),
        content: fileBase64,
        fileName: file.fileName,
        fileMime: file.fileMime
    });
  }

  function cancelCreate(e) {
    return navigate(-1)
  }

  let fileInput = useRef(null);

  let [ isSpinning, setIsSpinning ] = useState(null)
  async function withSpinner(fn) {
    console.log("spinning")
    setIsSpinning(true);
    return Promise.resolve(fn()).finally(_ => setIsSpinning(null))
  }

  return <>
    <Header/>
    <PageTitle>CREATE ASSET</PageTitle>
    <form onSubmit={onSubmitForm} >
      <div className="grid gap-6 grid-rows-1 ">
        {/* AssetId: auto
        Issuer: auto, me
        Date: auto, now
        Initial Owner ID: auto, me
        Prior -- not here
        Current Owner ID: auto me
        Instant Relay: auto
        Asset Integrity Line: auto */}

        <div>
          <label htmlFor="create-asset-name"
                 className="block text-sm mb-2" >
            Asset name
          </label>
          <input id="create-asset-name"
                name="name"
                placeholder="Enter name"
                required
                className="w-full px-2 py-1 rounded bg-zinc-700 text-zinc-100"/>
        </div>
        <div>
          <label htmlFor="create-asset-desc"
                 className="block text-sm mb-2">
            Description
          </label>
          <textarea
            id="create-asset-desc"
            name="description"
            placeholder="Enter description"
            className="w-full px-2 py-1 rounded bg-zinc-700 text-zinc-100">
          </textarea>
        </div>
        <input ref={fileInput}
               type="file"
               className="file:border-0 file:text-white file:bg-gray-500 file:rounded-md file:font-medium file:text-sm file:px-5 file:py-2 focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:cursor-not-allowed disabled:opacity-50"
               onChange={fileSelect}/>
        <div className="flex">
          <Spinner hidden={!isSpinning} className="!size-5"/>
          <div hidden={!isSpinning} className="text-info mx-2">Creating...</div>
        </div>
        <div className="fixed flex grow left-0 px-6 bottom-8 ~right-0 w-full flex-row justify-between">
          <ButtonCancel type="button" onClick={cancelCreate}>Back</ButtonCancel>
          <ButtonOk type="submit" disabled={!file || isSpinning}>Create</ButtonOk>
        </div>
      </div>
    </form>
  </>
}
