/**
 *
 * @param {object} param0
 * @param {string} param0.id
 * @param {string} param0.label
 * @param {string} param0.name
 * @param {boolean} param0.required
 * @param {boolean} param0.disabled
 * @param {Function} param0.onChange
 * @param {string} param0.placeholder
 * @returns
 */
export function TextInput({
  id, label, name, required, disabled, pattern, placeholder,
  ...props}) {

  return <div className="flex flex-col gap-2">
    <label className="block text-sm font-medium text-zinc-400" htmlFor={id}>{label}</label>
    <input
      pattern={pattern}
      id={id}
      name={name}
      required={required}
      disabled={disabled}
      placeholder={placeholder}
      className="w-full px-2 py-1 rounded text-sm bg-zinc-700 disabled:text-zinc-400  text-zinc-100 invalid:text-error " />
  </div>
}
