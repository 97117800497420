import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useMutation } from "@tanstack/react-query";
import { useProfiles } from "../lib/profiles";
import { AdminTwinClient } from "../lib/client";
import { Header, PageTitle } from "../Components";
import { TextInput } from "../Components/Input";
import { ButtonOk, CopyButton } from "../Components/Buttons";
import { Spinner,  } from "../Components/Spinner";


function TwinInfo({twin}) {
  return <>
    <div className="flex flex-col gap-1 select-none">
      <div className="flex flex-row justify-between">
        <p className="text-sm font-medium text-zinc-400">Account URL</p>
        <CopyButton value={twin.hostname} title="Copy hostname" className=" size-5"/>
      </div>
      <p className="max-w-fit text-wrap break-all border rounded p-1">{twin.hostname}</p>
    </div>
    <div className="flex flex-col gap-1 select-none">
      <div className="flex flex-row justify-between">
        <p className="text-sm font-medium text-zinc-400">Account Access Code</p>
        <CopyButton value={twin.apiKey} title="Copy API key" className="align-baseline size-5"/>
      </div>
      <p className="text-wrap border rounded p-1 break-words">{twin.apiKey}</p>
    </div>
  </>
}

export function Signup() {
  let navigate = useNavigate()
  let { setProfile, setActiveProfile } = useProfiles();
  let [ newTwin, setNewTwin ] = useState(null)
  let [ isCreating, setIsCreating ] = useState(null);
  let { mutate: createTwin } = useMutation({
    onMutate: _ => setIsCreating(true),
    onSettled: _ => setIsCreating(false),
    mutationFn: async function createTwinMutation({name, email}) {

      console.log(name, email) // We'll use this to create a persona

      let tetherHash = "4172f9e82c0af9eff943d41ff7b7d8c40741aaba8a10e6f09ebb1c011650f11945"
      // let tetherHash = await TwinClient.getLatestTether();
      // This complains that CORS is an issue; works only by disabling CORS in my browser
      // return (new AdminTwinClient({
      //   url: "https://admin.tq.biz-staging.todaq.net",
      //   apiKey: "1e13c4bc-23e7-4513-8353-414a0c182ba3"
      // })).createTwin({ tetherHash })
      return withSpinner(_ => (new AdminTwinClient({
        url: "https://admin.tracker-demo.biz.todaq.net",
        apiKey: "72fd41b4-e73b-4d19-a17d-8eeeab0c38fa"
      })).createTwin({ tetherHash }))
      // SECURITY(sfertman): this api key should reside somewhere on the backend; maybe AWS KMS via Lambda proxy to twin admin.
    },
    onSuccess: function createTwinSuccess({ hostname, apiKeys }) {
      console.log("resolved")
      setNewTwin({ hostname: `https://${hostname}`, apiKey: apiKeys[0] });
    }
  })
  // Example twins we can work with:

  // https://41f33a6c397b4bca97bb5616acd7fe39.tracker-demo.biz.todaq.net
  // c99c7e58-a71e-4529-8b88-3cf5dc92a7a3

  // https://4103432af1f870c5289fb951ad236d7d.tracker-demo.biz.todaq.net
  // 69de0132-60ea-4b30-8faa-efbdbfa22ec6


  // Staging twins
  // https://411026958e9e2a4c7852367bbe018075.tq.biz-staging.todaq.net
  // 92a511a7-988a-4880-becb-101af1291b98

  // https://4125b9aaebfb403b51827de29887103b.tq.biz-staging.todaq.net
  // 9f671957-5c3d-4393-9fd4-122e8aec5a92


  function onSubmit(e) {
    e.preventDefault();
    let formData = new FormData(e.target);
    let name = formData.get("name");
    let email = formData.get("email");
    withSpinner(() => {
      createTwin({ name, email });
    })
  }

  let [ isSpinning, setIsSpinning ] = useState(null);
  async function withSpinner(fn) {
    setIsSpinning(true);
    return Promise.resolve(fn()).finally(_ => setIsSpinning(null))
  }

  return <>
    <Header />
    {newTwin ?
      <div className="flex flex-col gap-6 px-6 size-fit">
        <PageTitle>New Twin Created</PageTitle>
        <TwinInfo twin={newTwin} />
        <ButtonOk onClick={()=>{
          let {hostname: url, apiKey} = newTwin;
          setProfile(url, {client: {url, apiKey}});
          setActiveProfile(url)
          return navigate("/home")
          }}>
            Login
        </ButtonOk>
      </div>
      :
      <form onSubmit={onSubmit} className="flex flex-col gap-6">
        <PageTitle>Create New Twin</PageTitle>
        <TextInput
          label="Name"
          id="input_name"
          name="name"
          required
          disabled={isCreating}
          placeholder="Enter name"/>
        <TextInput
          label="Email"
          id="input_email"
          name="email"
          required
          disabled={isCreating}
          pattern="[a-z0-9._%+\-]+@[a-z0-9.\-]+\.[a-z]{2,}$"
          placeholder="Enter email"/>
        <ButtonOk type="submit" disabled={isCreating}>Request account</ButtonOk>
        <div className="flex flex-row gap-2" >
          <Spinner className="!size-5" hidden={!isSpinning} />
          <div hidden={!isSpinning} className="text-info">Creating </div>
        </div>
      </form>
    }
  </>
}
