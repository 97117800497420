import { useNavigate, useParams } from "react-router-dom"
import { useQuery } from "@tanstack/react-query";
import { hexToBytes, useActiveClient } from "../lib/client";
import { Hash, HashDisplay, Header, PageTitle, Tuple } from "../Components";
import { bytesToHex } from "../lib/client";
import { useMemo } from "react";
import { fromBytes as ssrFromBytes } from "../lib/ssr";
import { ButtonCancel, ButtonOk } from "../Components/Buttons";
import { Spinner } from "../Components/Spinner";


function toDTG(date) {
  // Ensure the date is a Date object
  if (!(date instanceof Date)) {
    date = new Date(date);
  }

  // Day of the month with leading zero
  let day = String(date.getUTCDate()).padStart(2, '0');

  // Hours in 24-hour format with leading zero
  let hours = String(date.getUTCHours()).padStart(2, '0');

  // Minutes with leading zero
  let minutes = String(date.getUTCMinutes()).padStart(2, '0');

  // Month as three letter abbreviation
  const monthNames = ["JAN", "FEB", "MAR", "APR", "MAY", "JUN",
    "JUL", "AUG", "SEP", "OCT", "NOV", "DEC"];
  let month = monthNames[date.getUTCMonth()];

  // Full year
  let year = date.getUTCFullYear();

  // Combine all parts into the DTG format
  return `${day}${hours}${minutes}Z${month}${year}`;
}

function b64toBlob(b64Data, contentType = '', sliceSize = 512) {
  const byteCharacters = atob(b64Data);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }
  const blob = new Blob(byteArrays, { type: contentType });
  return blob;
}

export function AssetComponent({ client, ssr }) {
  if (!ssr) return <></>;
  let date = ssr.timestamp ? toDTG(ssr.timestamp) : "Unknown";
  let blob = b64toBlob(ssr.content.content, ssr.content?.fileMime);
  let mime = blob.type;
  let url = URL.createObjectURL(blob);

  return <>
    <div className="flex flex-col items-center justify-between">
      <div className="w-full align-center ">
        <PageTitle> {ssr?.content?.name} </PageTitle>
        {client &&
          <div className="mb-5 h-5 bg-[url(/public/img/download-file.svg)] bg-no-repeat">
            <a  href={client.downloadLink(ssr.current.getHash()).toString()}
                title="Download asset"
                className="pl-7 hover:underline text-zinc-100">
              Download asset
            </a>
          </div> }
        <Tuple position="0">Issuer ID <Hash h={ssr.first.tether().getHash().toString()} /></Tuple>

        <Tuple position="1">Date {date}</Tuple>
        <Tuple position="2">Initial Owner ID <Hash h={ssr.first.tether().getHash().toString()} /></Tuple>
        { ssr.current.knownHistory().length > 2 &&
          <Tuple position="3">
            Prior owners
            <ul>{ssr.history.slice(1,-1).map(h =>
              <li key={ h.tether().getHash().toString() } className="text-right"><Hash h={h.tether().getHash().toString()} /></li>)}
            </ul>
          </Tuple>}
        <Tuple position="4">Current owner <Hash h={ssr.current.getTetherHash().toString()} /></Tuple>
        {/* // HAAAACK(sfertman): ^^ see issue #41 */}
        <Tuple position="5">Description {ssr.content.description || "N/A"}</Tuple>
        <Tuple position="6">Asset Integrity Line <span>TODAQ</span></Tuple>
        <Tuple position="7">Instant Relay <span>TODAQ</span></Tuple>

        <div className="flex flex-col w-full gap-4 my-2 items-baseline">
        <p>Attached content</p>
        <div className="mb-5 h-5 bg-[url(/public/img/download-file.svg)] bg-no-repeat">
        <a href={url} download={ssr?.content?.fileName}
    title="Download asset"
    className="pl-7 hover:underline text-zinc-100">
        Download attached file
        </a>
        </div>
          <p className="w-full h-fit px-2 py-1 rounded bg-zinc-700 text-zinc-100 text-wrap break-words">
            {mime.startsWith('image') && <img src={url} /> }
          </p>
        </div>
      </div>
    </div>
  </>

}

export default function Asset() {
  let navigate = useNavigate();
  let { hash } = useParams();
  let twinClient = useActiveClient();
  let { isPending, isError, data: fileBytes, error }= useQuery({
    queryKey: ["fetch", hash],
    queryFn: async function fetchFileQuery() {
      return await twinClient.fetch(hash)
        .then(buff => bytesToHex(new Uint8Array(buff)));
    },
    staleTime: Infinity
  });

  let ssr = useMemo(() => {
    if (!fileBytes) return null;
    // This means we fetch did not finish yet

    if (fileBytes.data) return null;
    // This means some error occurred -- should probably differentiate between the two

    try {
      let bytes = hexToBytes(fileBytes);
      let ssr = ssrFromBytes(bytes);
      if (!ssr) return null;
      try {
        ssr.content = JSON.parse(ssr?.content); // try parsing content as json
      } catch (_) { }
      return ssr
    } catch (e) {
      console.warn(e);
    }
  }, [fileBytes]);

  if (isPending) {
    return <>
      <Header />
      <div className="flex gap-2">
        <Spinner className="!size-5"/>
        <div className="flex gap-2 text-info align-text-bottom">
          Retrieving asset <HashDisplay h={hash} />
        </div>
      </div>
    </>
  }

  if (!isPending && isError) {
    if (error?.data?.status == 404) {
      return <>
        <Header/>
        <div className="flex flex-col gap-6">
          <div className="bg-red-800 flex gap-1 justify-center" >
            Asset <HashDisplay h={hash}/> not found!
          </div>
          <ButtonCancel onClick={() => navigate(`/inventory`)}>
            Back to inventory
          </ButtonCancel>
        </div>
      </>
    }

    return <>
      <Header />
      <div className="flex flex-col gap-6">
        <div className="gap-6 bg-red-800">
          <div className="bg-red-800 text-center" >
            Error retrieving asset <Hash h={hash} />
          </div>
          <pre className="bg-red-800 mt-6 p-2">
            {JSON.stringify(error.data, null, 2)}
          </pre>
        </div>
        <ButtonCancel onClick={() => navigate(`/inventory`)}>
          Back to inventory
        </ButtonCancel>
      </div>
    </>
  }

  if (!ssr) return <>
    <Header />
  </>

  return <>
    <Header />
    <AssetComponent client={twinClient} ssr={ssr} />
    <div className="fixed flex grow left-0 px-6 bottom-8 w-full flex-row justify-center">
      <ButtonCancel hidden={!!ssr} onClick={() => navigate(`/inventory`)}>
        Back to inventory
      </ButtonCancel>
      <ButtonOk hidden={!ssr} onClick={() => navigate(`/asset/${hash}/transfer`)}>
        Transfer asset
      </ButtonOk>
    </div>
  </>
}
