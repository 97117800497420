import info from "../info.json"
import { CopyButton } from "./Buttons";
import { ReactComponent as CopySVG } from "../svg/copy.svg";
import { forwardRef, useRef, useState } from "react";
import { useClickOutside } from "../util";
import { AddressCard } from "./Address";

export * from "./Header.js";

export function Footer() {
  return <div className="text-[0.75rem] flex items-center justify-between  w-full text-gray-800">
    <div>TRACKER by TODAQ 2024</div>
    <div className="text-center">CORPORATE CONFIDENTIAL</div>
    <div> Build: {info.build} {info.env && `@${info.env}`} </div>
  </div>
}

export function Tuple({children, position}) {
  return <div className="flex flex-col w-full gap-2 mb-5">
    <div className="text-left text-gray-700  whitespace-nowrap">{children[0]}</div>
    <div className="flex justify-between items-center">
      <div className="text-left flex-1 font-semibold">{children[1]}</div>
      <div className="text-green-600 text-sm text-right min-w-[5rem] ~border verified" style={{'--delay': `0.${position}s`}}>Verified</div>
    </div>
  </div>;
}

export function WithCopyOnHover({text, title="Copy", children, className}) {
  return <div className={["flex flex-row items-center group/coh", className].join(" ")}>
    {children}
    <CopyButton
      value={text}
      title={title}
      className="
        hidden group-hover/coh:block
        h-4 aspect-square hover:scale-110"
        />
  </div>
}

export function Hash({h,n=7,className, ...props}) {
  let editState = useState(false)
  let [_, setIsEditing] = editState;
  const Menu = forwardRef(function(props, ref) {

    let { state: [isMenuOpen, setIsMenuOpen], className,...rest } = props
    function copyHash(e) {
      e.preventDefault()
      setIsMenuOpen(false);
      navigator.clipboard.writeText(h);
    }

    function addAlias(e){
      e.preventDefault();
      setIsMenuOpen(false)
      setIsEditing(true)
      console.log("adding alias")
    }

    if (!isMenuOpen) return <></>
    return <ul ref={ref} className={[
      "absolute left-0 block border border-menu-border rounded bg-menu-bg px-1 py-1 w-fit font-normal", className].join(" ")} {...rest}>
      <li onClick={copyHash} className="block cursor-pointer rounded hover:bg-menu-hover px-1 text-left text-nowrap">Copy hash</li>
      <li onClick={addAlias} className="block cursor-pointer rounded hover:bg-menu-hover px-1 text-left text-nowrap">Edit alias</li>
    </ul>
  })

  if (!h) return <></>;

  return <WithContextMenu CtxMenu={Menu}>
    <AddressCard h={h} state={editState}/>
  </WithContextMenu>
}

export function WithContextMenu(props) {
  let menuState = useState(false);
  let [ _, setIsMenuOpen ] = menuState;

  function showMenu(e) {
    e.preventDefault()
    setIsMenuOpen((state) => !state)
  }

  function hideMenu(e) {
    e.preventDefault()
    setIsMenuOpen(false)
  }
  let menuRef = useRef(null)
  useClickOutside(hideMenu, menuRef);

  let { CtxMenu, className, children,...rest } = props;

  return <div className={["relative inline-block", className].join(" ")} onContextMenu={showMenu} {...rest} >
    {children}
    <CtxMenu className="z-50" ref={menuRef} state={menuState} />
  </div>
}


export function PageTitle({children}) {
  return <div className="my-4 font-semibold underline uppercase">
    {children}
  </div>
}

export function HashDisplay(props) {
  // TODO: make a new namespace: Components/Hash
  let { h, n=7, fontSize, ...rest } = props
  if (!h) return <></>
  let hashText = h.slice(0,n)
  return (
    <div className="flex flex-row max-w-fit select-none">
      <div title={h}
        className="pr-0.5 text-left truncate w-fit font-mono" style={{ fontSize }}>
        {hashText}
      </div>
      <button title="Copy hash"
        type="button"
        onClick={() => navigator.clipboard.writeText(h)}
        className="hidden group-hover/address:block group-hover/hash:block rounded-sm active:backdrop-brightness-90 min-w-fit">
        <CopySVG className="shrink-0" style={{ width: `calc(${fontSize})` }} />
      </button>
    </div>
  );
}
